<template>
  <div>
    <v-select
      v-for="(field, i) in fieldObjects" :key="i"
      :items="fields"
      v-model="registration.fieldPref[i]"
      :label="`${strings[i]} Choice`"
      item-text="name"
      item-value="name"
      item-color="color3"
      color="color3"
      @change="onChange(i)"
    ></v-select>
    <v-list v-if="false">
      <v-list-item
        v-for="(field, i) in fieldObjects"
        :key="i"
      >
        <v-list-item-icon>
          {{i}}
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="field.name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ['tournament', 'registration', 'active'],
  data () {
    return {
      last: []
    }
  },
  computed: {
    strings () {
      return ['First', 'Second', 'Third', 'Fourth', 'Fifth']
    },
    fields () {
      return this.tournament.props.includes('fields') && this.tournament.jProps && this.tournament.jProps.fields && this.tournament.jProps.fields.filter(f => f.divisionIds.includes(this.registration.division.id) && !f.hide)
    },
    fieldObjects () {
      return this.registration.fieldPref && this.registration.fieldPref.map(m => this.fields.find(f => f.name === m))
    }
  },
  methods: {
    init () {
      if (this.registration.fieldPref.length === 0) this.registration.fieldPref = this.fields.map(m => m.name)
      this.last = JSON.parse(JSON.stringify(this.registration.fieldPref))
    },
    onChange (i) {
      const newField = this.fieldObjects[i].name
      const clone = JSON.parse(JSON.stringify(this.last))
      clone.splice(clone.findIndex(f => f === newField), 1)
      clone.splice(i, 0, newField)
      this.registration.fieldPref = clone
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    active: 'init'
  }
}
</script>
